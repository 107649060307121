import { Star } from "@components/star";
import { TProduct } from "@components/types";
import {
    getFirst,
    getImageObject,
    getLength,
    getSafeUrl,
    humanizeCurrency,
    sanityIoImageLoader,
} from "@core/utils";
import Image from "next/image";
import Link from "next/link";
import React from "react";

type Props = {
    product: TProduct;
    loading?: "lazy" | "eager";
};

export const RichCardV2: React.FC<Props> = ({ product, loading }) => {
    return (
        <div className="relative flex-shrink-0 w-36 rounded overflow-hidden shadow-md">
            {product.tag && (
                <div className="absolute top-3 right-2 z-10 w-max rounded font-medium text-neutral-50 bg-brand-900 py-0.5 px-2 select-none uppercase">
                    {product.tag}
                </div>
            )}
            <Link href={`/product/${product.id}/${getSafeUrl(product.name)}`} prefetch={false}>
            <div className="w-full h-full text-center flex flex-col">
                <div className="flex justify-center">
                    
                        <Image
                            loading={loading}
                            loader={sanityIoImageLoader}
                            src={getImageObject(product.images)?.url}
                            alt={getImageObject(product.images)?.description || product.name || 'Product'}
                            width="144"
                            height="144"
                            className="flex items-center justify-center h-36 object-contain aspect-square rounded"
                        />
                    {/* </Link> */}
                </div>
                <div className="flex flex-col justify-star gap-1.5 p-2 h-full">
                    <div className="leading-tight font-medium font-simple overflow-hidden h-8 pb-1">
                        <p className="text-sm text-neutral-700 text-left break-words line-clamp-2 leading-tight font-semibold font-manrope">
                            {product.name}
                        </p>
                    </div>
                    <div className="flex flex-row justify-between">
                        {getLength(product?.inventories) > 0 ? (
                            <div className="flex flex-row gap-2 items-center">
                                <div>
                                    <span className="text-sm text-neutral-800 font-manrope font-semibold">
                                        {humanizeCurrency(getFirst(product?.inventories)?.price) || humanizeCurrency(getFirst(product?.inventories)?.mrp)}
                                    </span>
                                </div>
                                <div>
                                {getFirst(product?.inventories)?.price !== getFirst(product?.inventories)?.mrp && (
                                    <span className="text-xs text-neutral-600 font-manrope font-medium line-through">
                                        {humanizeCurrency(getFirst(product?.inventories)?.mrp || product.mrp)}
                                    </span>
                                )}
                                </div>
                            </div>
                        ) : (
                            <span className="text-sm text-error-400 font-semibold">Unavailable</span>
                        )}
                        {product?.stats?.rating_overall ? (
                            <Star rating={product?.stats?.rating_overall} />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
            </Link>
        </div>
    );
};
