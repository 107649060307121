import { AddToCart } from "@components/addtocart";
import { Star } from "@components/star";
import { TProduct } from "@components/types";
import { getFirst, getImageObject, getSafeUrl, humanizeCurrency, sanityIoImageLoader } from '@core/utils';
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { FaStar } from "react-icons/fa";

type Props = {
    product: TProduct;
};


export const ProductCardWithProvider: React.FC<Props> = ({ product }) => {
    let firstInventory = getFirst(product?.inventories)
    let storeRating = firstInventory?.store?.stats?.rating_overall;
    let isFnB = firstInventory?.store?.category_id == 1;
    let externalLink = firstInventory?.external_link || "";
    let isOnline = externalLink?.length > 0;

    return (
        <div className="min-h-36 max-w-120 flex justify-center flex-col bg-neutral-50 border border-solid border-neutral-300 rounded-lg font-manrope overflow-hidden">
            {isOnline &&
                <div className="flex justify-between items-center px-2 py-1 border-b bg-neutral-200">
                    <span className="font-semibold md:text-lg text-neutral-900 text-base">{firstInventory?.store?.name}
                    </span>
                    {externalLink?.length > 0 &&
                        <div className="flex gap-1 items-center">
                            <Link href={externalLink} >
                                <Image
                                    loader={sanityIoImageLoader}
                                    src={firstInventory?.store?.logo || "https://cdn.jhattse.com/public/assets/noimage.png"}
                                    width="36"
                                    height="36"
                                    className="rounded-sm"
                                    alt={firstInventory?.store?.name || ''}
                                />
                            </Link>
                        </div>
                    }
                </div>
            }
            {isFnB &&
                <div className="flex justify-center items-center p-2 border-b bg-neutral-200">
                    <span className="font-semibold text-lg text-custom_gray lt-sm:text-base">{firstInventory?.store?.name}
                    </span>
                    {storeRating !== undefined && storeRating > 0 &&
                        <div className="flex gap-1 items-center">
                            <p className="font-bold text-custom_gray text-base pr-1">{storeRating?.toFixed(1)}</p>
                            {
                                Array.from({ length: storeRating }).map((_, i) => (
                                    <span className="text-custom_yellow"><FaStar key={i} /></span>
                                ))
                            }
                            {
                                Array.from({ length: 5 - storeRating }).map((_, i) => (
                                    <span className="text-radioButtonBorder"><FaStar key={i} /></span>
                                ))
                            }
                        </div>
                    }
                </div>
            }
            {product?.tag &&
                <div className="absolute top-3 right-0 z-10 w-max rounded-l-md font-medium text-neutral-700 bg-brand-500 py-0.5 px-2 select-none">
                    {product.tag}
                </div>
            }
            <div className="flex flex-row md:gap-4 md:h-36 h-32 h-full gap-2 items-center">
                <div className="flex justify-start h-full">
                    <Link href={`/product/${product?.id}/${getSafeUrl(product?.name)}`} prefetch={false}>
                        <div className="relative md:h-36 h-32 h-full">
                            <Image
                                loader={sanityIoImageLoader}
                                src={getImageObject(product?.images)?.url}
                                alt={getImageObject(product?.images)?.description || product?.name || 'Product'}
                                width="160"
                                height="160"
                                className="max-h-36 md:w-36 w-32 h-full object-cover scale-100 hover:scale-105 focus:scale-105 ease-in duration-200 rounded-l-md hover:rounded-md"
                            />
                        </div>
                    </Link>
                </div>
                <div className="h-full w-full">
                    <div className="flex flex-col justify-between font-bold font-manrope overflow-hidden px-2 pl-0 h-full" >
                        <div className="pt-2">
                            <Link href={`/product/${product?.id}/${getSafeUrl(product?.name)}`} prefetch={false}>
                                <p className="font-semibold line-clamp-1 md:text-base text-sm text-neutral-900">{product?.name}</p>
                            </Link>
                            {
                                product?.stats != null && product?.stats != undefined && product?.stats?.rating_count > 0 ?
                                    <div className="pb-1">
                                        <Star rating={product?.stats?.rating_overall} />
                                        {/* <p className="text-neutral-500">{product?.stats?.rating_count}+ ratings</p> */}
                                    </div>
                                    :
                                    <div className="py-2 text-sm text-error-300 font-manrope font-medium">No Ratings</div>
                            }
                        </div>
                        <div className="flex flex-col gap-2 justify-between py-0.5 lt-sm:flex-row lt-sm:items-center pb-2">
                            <div className="text-neutral-800 font-bold md:text-lg text-base">
                                <span>{humanizeCurrency(getFirst(product?.inventories)?.price || product?.mrp)}</span>
                            </div>
                            {product?.inventories?.length != 0 ?
                                <div className="flex flex-row gap-4 items-center w-36">
                                    <AddToCart addToCartBag={false} product={product} inventory={getFirst(product?.inventories)}></AddToCart>
                                </div>
                                :
                                <div className="flex text-error-500">Unavailable</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}