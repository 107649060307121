import React, { useEffect, useState } from 'react';
import { TGeoLocation, TInventory, TProduct } from '@components/types';
import { useRecoilState } from 'recoil';
import { cartState } from '@recoil/atoms';
import { AiOutlinePlus, AiOutlineMinus, AiOutlineDelete } from 'react-icons/ai';
import Link from 'next/link';
import { variantState } from '@recoil/atoms/variant';
import { geoLocationState } from '@recoil/atoms/address';
import { getLocation } from '@core/geolocation';
import { notification } from 'antd';
type Props = {
  product: TProduct;
  inventory: TInventory;
  btnStyle?: string;
  Isvariant?: boolean;
  isAvailable?: boolean;
};

export const CartButton = ({
  product,
  inventory,
  btnStyle,
  Isvariant = false,
  isAvailable
}: Props) => {
  const [cart, setCart] = useRecoilState(cartState);
  const [Variant, setVariant] = useRecoilState(variantState);
  const [geoPosition, setGeoposition] = useRecoilState(geoLocationState);
  const [api, contextHolder] = notification.useNotification();

  const checkStale = (location: TGeoLocation) => {
    return Date.now() - location.last_updated > 60000;
  };

  const increase = (product: TProduct, inventoryObj: TInventory, quantity: number = 1) => {
    if (geoPosition?.latitude > 0 && geoPosition?.longitude > 0) {
      let inventory: TInventory = JSON.parse(JSON.stringify(inventoryObj))
      const finalId = `${inventory?.variant?.id}${inventory?.addons?.length > 0 ? "_" + inventory?.addons?.sort((a, b) => a.id - b.id).map((x) => x.id).join("_") : ""}`
      if (product?.variants?.length > 1) {
        setVariant({ Isvariant: true, product: product, showModal: true, quantity: productQuantity, inventory: inventory })
      }
      else if (inventory?.addons?.length > 0) {
        setVariant({ Isvariant: true, product: product, showModal: true, quantity: productQuantity, inventory: inventory })
      }
      else {
        setCart((cart) => {
          let addons = cart.get(finalId)?.addon
          if (cart.has(finalId)) {
            let newQuantity =
              cart.get(finalId).quantity + quantity;
            if (newQuantity == 0 || newQuantity == undefined) {
              cart.delete(finalId);
            } else {
              cart.set(finalId, {
                internalId: finalId,
                product: product,
                quantity: newQuantity,
                inventory: inventory,
                deliverable: inventory?.store?.is_delivery || false,
                addon: addons
              });
            }
          } else {
            cart.set(finalId, {
              internalId: finalId,
              product: product,
              quantity: quantity,
              inventory: inventory,
              deliverable: inventory?.store?.is_delivery || false,
              addon: addons
            });
          }
          window?.fbq('track', 'AddToCart', {
            content_name: `${product?.name || inventory?.product?.name}`,
            content_category: `${product?.category?.name || inventory?.product?.category?.name}`,
            content_ids: [`${finalId || product?.id || inventory?.id}`],
            content_type: 'product',
            value: inventory?.price || product?.mrp,
            currency: 'RS'
          });
          return new Map(cart);
        });
      }
    } else {
      getLocation()?.then((location) => {
        if (checkStale(location)) {
          api.error({
            message: 'Please enable location access to use this feature.',
            placement: 'bottomRight',
          });
        } else {
          setGeoposition({ last_updated: 0, longitude: location?.longitude, latitude: location?.latitude });
        }
      })
    }
  };
  const productQuantity =
    inventory?.variant_id != undefined
      ? cart.get(inventory?.variant_id?.toString())?.quantity
      : 0;
  return productQuantity == 0 || productQuantity == undefined ?
    (
      inventory?.external_link?.length > 0 ?
        (
          <div
            className={`grid ${productQuantity == 0 || productQuantity == undefined
              ? 'grid-cols-1'
              : 'grid-cols-3 divide-x rounded shadow'
              } justify-center items-center max-w-lg min-w-24 text-neutral-50`}
          >
            <Link
              onClick={() => {
                window?.fbq('trackCustom', 'BuyNowClicked', {
                  link: inventory?.external_link,
                  content_name: `${product?.name || inventory?.product?.name}`,
                  content_category: `${product?.category?.name || inventory?.product?.category?.name}`,
                  content_ids: [`${product?.id || inventory?.id}`],
                  content_type: 'product',
                  value: inventory?.price || product?.mrp,
                  currency: 'RS'
                });
              }}
              href={inventory?.external_link}
              className="lg:h-8 h-7 border border-solid border-brand-500 bg-brand-500 text-neutral-50 md:px-9 px-2 hover:opacity-80 focus:opacity-80 md:text-base text-sm font-medium whitespace-nowrap select-none md:rounded-md rounded flex justify-center items-center font-manrope"
            >
              BUY NOW
            </Link>
          </div>
        ) :
        (
          <div
            className={`grid ${productQuantity == 0 || productQuantity == undefined
              ? 'grid-cols-1'
              : 'grid-cols-3 divide-x rounded shadow'
              } justify-center items-center max-w-lg min-w-24 w-full`}
          >
            <button
              onClick={() => increase(product, inventory)}
              className={`${isAvailable ? "cursor-pointer" : "cursor-not-allowed"} lg:h-8 h-7 text-brand_indigo brand-neutral-50 hover:opacity-80 focus:opacity-80 px-2 md:px-4 lg:text-lg md:text-base text-sm text-center font-semibold whitespace-nowrap select-none md:rounded-md border s-brand_indigo border-solid border-1 rounded font-manrope cursor-pointer`}
              disabled={!isAvailable}
            >
              ADD
            </button>
          </div>
        )
    )
    :
    (
      <div
        className={`lg:h-8 h-7 grid ${productQuantity == 0 || productQuantity == undefined
          ? 'grid-cols-1'
          : 'grid-cols-3 font-semibold'
          } text-base justify-center items-center ${btnStyle ? btnStyle : 'text-brand_indigo'
          } min-w-full`}
      >
        <div className="col-span-1 flex justify-start items-center">
          <button
            className="flex items-center lg:h-8 h-7 lg:px-2 px-1.5 text-neutral-50 bg-brand_indigo border-1 border-solid border-brand_indigo rounded-md"
            onClick={(event) => increase(product, inventory, -1)}
          >
            {productQuantity == 1 ? <AiOutlineDelete /> : <AiOutlineMinus />}
          </button>
        </div>
        <div className="col-span-1 justify-center items-center text-center">
          <span className="font-manrope text-brand_indigo">{productQuantity}</span>
        </div>
        <div className="col-span-1 flex justify-end items-center h-full">
          <button
            className="flex items-center lg:h-8 h-7 lg:px-2 px-1.5 text-neutral-50 bg-brand_indigo border-1 border-solid border-brand_indigo rounded-md"
            onClick={(event) => increase(product, inventory)}
            title="Increase Quantity"
          >
            <AiOutlinePlus />
          </button>
        </div>
      </div>
    );
};
