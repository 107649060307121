import { getOrderHistory } from '@api/order';
import { TOrder } from '@components/types';
import { Drawer, Steps, message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FaShoppingBag } from 'react-icons/fa';
import { MdCheckCircleOutline, MdClose } from 'react-icons/md';

type Props = {
    showViewDetails?: boolean,
    setShowViewDetails?: Function,
    order?: TOrder
}

const Ordertracker = ({ showViewDetails, setShowViewDetails, order }: Props) => {
    const [orderHistoryOptions, setOrderHistoryOptions] = useState([])

    const fetchOrderHistory = (refresh: boolean = false) => {
        getOrderHistory(order?.id)?.then((res: any) => {
            let temp = res.map((item: any) => {
                return {
                    title: <h2 className='text-neutral-900 font-medium'>{item?.title}</h2>,
                    description: <><p className='text-neutral-600'>{item?.message}</p><p className='text-neutral-500'>{moment(item?.added_on)?.format("DD/MM/YYYY, hh:mm a")}</p></>,
                    category: item?.category,
                }
            })
            setOrderHistoryOptions(temp.reverse())
            if (refresh) {
                message?.success("Refresh successfully!")
            }
        })
    }

    useEffect(() => {
        if (showViewDetails) {
            fetchOrderHistory()
        }
    }, [showViewDetails])


    return (
        <>
            <Drawer placement="right" headerStyle={{ borderBottom: "none" }} onClose={() => setShowViewDetails(false)} closable={false} open={showViewDetails}>
                <div className="flex flex-col justify-between gap-2 grow h-full">
                    <div className="flex flex-col gap-4 font-manrope md:text-base text-sm">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                                <h2 className="font-bold font-manrope text-lg text-neutral-700">Track Order</h2>
                                <button
                                    onClick={() => fetchOrderHistory(true)}
                                    className="text-xs border-1 p-1 px-2 cursor-pointer border-brand-100 text-brand bg-brand-100/50 rounded font-medium">Refresh</button>
                            </div>
                            <button onClick={() => { setShowViewDetails(false) }}><MdClose /></button>
                        </div>
                        <div className="bg-neutral-50 flex flex-col gap-2 p-2 rounded-md border border-solid border-neutral-300">
                            <div className='flex flex-col items-center justify-between'>
                                <div className="font-manrope text-sm text-neutral-700 w-full"><span className="font-semibold">Order:</span> <span className="font-medium">#{order?.short_id}</span></div>
                                <div className="font-manrope text-sm text-neutral-700 w-full"><span className="font-semibold">Bill Id:</span> <span className="font-medium">{order?.bill_id}</span></div>
                                <div className="font-manrope text-sm text-neutral-700 w-full"><span className="font-semibold">Date:</span> <span className="font-medium">{`${moment(order?.added_on).format("DD MMM YYYY h:mm A")}`}</span></div>
                                <div className="font-manrope text-sm text-neutral-600 w-full"><span className="font-medium">Seller:</span> <span className="font-medium">{order?.store?.name}</span></div>
                                <div className="flex items-center justify-start w-full gap-1 pt-1">
                                    <h2 className="flex gap-1 items-center text-sm font-manrope font-normal text-green-600">{order?.status === "Completed" ? <MdCheckCircleOutline size={20} className="text-green-600" /> : <FaShoppingBag className="text-brand-500" />} <span className={`font-manrope font-medium font-manrope ${order?.status == "Completed" ? "text-green-600" : "text-neutral-700"}`}>{order?.status}</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 grow h-full justify-between">
                            {orderHistoryOptions?.length > 0 ? <Steps
                                current={orderHistoryOptions?.length - 1 || 1}
                                className='text-superone'
                                direction="vertical"
                                items={orderHistoryOptions}
                            />
                                : <div className='font-manrope text-neutral-600 text-sm'>Updates not available.</div>
                            }
                        </div>
                    </div>
                </div>
            </Drawer >

        </>
    )
}

export default Ordertracker