import { TProductCategory } from "@components/types";
import { getSafeUrl, sanityIoImageLoader } from '@core/utils';
import Image from "next/image";
import Link from "next/link";


type Props = {
    element: TProductCategory;
    loading?: "lazy" | "eager"
};


export const ProductCategoryCard = ({ element, loading }: Props) => {

    return (
        <div className="flex flex-col w-40 bg-neutral-100 border border-solid border-neutral-300 rounded-xl overflow-hidden">
            <div className="flex justify-center h-40 w-full bg-neutral-50 overflow-hidden">
                <Link href={`/category/${element?.id}/${getSafeUrl(element?.name)}`} prefetch={false}>
                    <Image
                        loader={sanityIoImageLoader}
                        src={element?.image || "https://jhattse.com/api/v1/file/?key=esangrah/UFVYFNUN-product-category.png"}
                        alt={element?.name}
                        width="160"
                        height="160"
                        className="w-full object-cover scale-100 hover:scale-105 focus:scale-105 ease-in duration-200"
                        loading={loading}
                    />
                </Link>
            </div>
            <div className="flex grow flex-col justify-between font-simple text-left overflow-hidden p-4">
                <div>
                    <p className="md:text-base text-sm text-neutral-700 text-ellipsis font-semibold break-words line-clamp-2 md:line-clamp-none leading-tight">{element?.name}</p>
                </div>
                <p className="flex gap-1 text-neutral-500 text-sm font-semibold"><span>{element?.total_products < 10 ? `${element?.total_products}` : `${Math.round(element?.total_products / 10) * 10}+`}</span> Products</p>
            </div>
        </div>
    )
}