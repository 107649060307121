import React, { useEffect, useState } from 'react';
import { TGeoLocation, TInventory, TProduct } from '@components/types';
import { useRecoilState } from 'recoil';
import { cartState } from '@recoil/atoms';
import { AiOutlinePlus, AiOutlineMinus, AiOutlineDelete } from 'react-icons/ai';
import Link from 'next/link';
import { variantState } from '@recoil/atoms/variant';
import { geoLocationState } from '@recoil/atoms/address';
import { getLocation } from '@core/geolocation';
import { message, notification } from 'antd';

type Props = {
  product: TProduct;
  inventory: TInventory;
  mode: string;
  btnSize?: string;
  btnStyle?: string;
  Isvariant?: boolean;
  variantId?: number;
};

export const CartButton = ({
  product,
  inventory,
  mode,
  btnSize,
  btnStyle,
  Isvariant = false,
  variantId
}: Props) => {
  const [cart, setCart] = useRecoilState(cartState);
  const [showModal, setShowModal] = useState(true);
  const [Variant, setVariant] = useRecoilState(variantState);
  const [geoPosition, setGeoposition] = useRecoilState(geoLocationState);
  const [api, contextHolder] = notification.useNotification();

  const checkStale = (location: TGeoLocation) => {
    return Date.now() - location.last_updated > 60000;
  };




  const increase = (product: TProduct, inventoryObj: TInventory, quantity: number = 1) => {
    if (geoPosition?.latitude > 0 && geoPosition?.longitude > 0) {
      let inventory: TInventory = JSON.parse(JSON.stringify(inventoryObj))
      const finalId = `${product?.id}${inventory?.addons.length > 0 ? "_" + inventory?.addons.sort((a, b) => a.id - b.id).map((x) => x.id).join("_") : ""}`
      const finalId2 = `${inventory?.variant?.id}${inventory?.addons.length > 0 ? "_" + inventory?.addons.sort((a, b) => a.id - b.id).map((x) => x.id).join("_") : ""}`
      if (product?.variants.length > 1 && typeof window !== 'undefined') {
        setVariant({
          Isvariant: true,
          product: product,
          showModal: showModal,
          quantity: productQuantity,
        });
      }
      else if (inventory?.addons?.length > 0) {
        setVariant({ Isvariant: true, product: product, showModal: showModal, quantity: productQuantity })
      }
      else if (product?.variants == undefined) {
        setCart((cart) => {
          let addons = cart.get(product?.id?.toString())?.addon
          if (cart.has(finalId)) {
            let newQuantity = cart.get(product?.id?.toString()).quantity + quantity;
            if (newQuantity == 0 || newQuantity == undefined) {
              cart.delete(finalId);
            } else {
              cart.set(finalId, {
                internalId: finalId,
                product: product,
                quantity: newQuantity,
                inventory: inventory,
                deliverable: inventory?.store?.is_delivery || false,
                addon: addons
              });
            }
          } else {
            cart.set(finalId, {
              internalId: finalId,
              product: product,
              quantity: quantity,
              inventory: inventory,
              deliverable: inventory?.store?.is_delivery || false,
              addon: addons
            });
          }
          return new Map(cart);
        });

      }
      inventory?.variant_id !== undefined &&
        inventory?.variant_id !== null &&
        setCart((cart) => {
          if (cart.has(finalId2)) {
            let newQuantity =
              cart.get(finalId2).quantity + quantity;
            if (newQuantity == 0 || newQuantity == undefined) {
              cart.delete(finalId2);
            } else {
              cart.set(finalId2, {
                internalId: finalId2,
                product: product,
                quantity: newQuantity,
                inventory: inventory,
                deliverable: inventory?.store?.is_delivery || false,
              });
            }
          } else {
            cart.set(finalId2, {
              internalId: finalId2,
              product: product,
              quantity: quantity,
              inventory: inventory,
              deliverable: inventory?.store?.is_delivery || false,
            });
          }
          window?.fbq('track', 'AddToCart', {
            content_name: `${product?.name || inventory?.product?.name}`,
            content_category: `${product?.category?.name || inventory?.product?.category?.name}`,
            content_ids: [`${finalId || product?.id || inventory?.id}`],
            content_type: 'product',
            value: inventory?.price || product?.mrp,
            currency: 'RS'
          });
          return new Map(cart);
        });
    } else {
      getLocation()?.then((location) => {
        if (checkStale(location)) {
          api.error({
            message: 'Please enable location access to use this feature.',
            placement: 'bottomRight',
          })
        } else {
          setGeoposition({ last_updated: 0, longitude: location?.longitude, latitude: location?.latitude });
        }
      })
    }
  }
  const productQuantity =
    inventory?.variant_id != undefined
      ? cart.get(inventory?.variant_id?.toString())?.quantity
      : 0;


  return productQuantity == 0 || productQuantity == undefined ?
    (
      inventory?.external_link?.length > 0 ? (
        <div
          className={`grid ${productQuantity == 0 || productQuantity == undefined
            ? 'grid-cols-1'
            : 'grid-cols-3 divide-x rounded shadow'
            } justify-center items-center max-w-lg min-w-full text-neutral-50`}
        >
          <Link
            onClick={() => {
              window?.fbq('trackCustom', 'BuyNowClicked', {
                link: inventory?.external_link,
                content_name: `${product?.name || inventory?.product?.name}`,
                content_category: `${product?.category?.name || inventory?.product?.category?.name}`,
                content_ids: [`${product?.id || inventory?.id}`],
                content_type: 'product',
                value: inventory?.price || product?.mrp,
                currency: 'RS'
              });
            }}
            href={inventory?.external_link}
            className="flex items-center justify-center lg:h-8 h-7 bg-brand-500 text-neutral-50 hover:opacity-80 focus:opacity-80 px-2 md:px-4 md:text-base text-sm text-center font-medium whitespace-nowrap select-none md:rounded-md border border-brand-500 border-solid rounded font-manrope cursor-pointer"
          >
            BUY NOW
          </Link>
        </div>
      ) : (
        <div
          className={`grid ${productQuantity == 0 || productQuantity == undefined
            ? 'grid-cols-1'
            : 'grid-cols-3 divide-x rounded shadow'
            } justify-center items-center max-w-lg min-w-full`}
        >
          <button
            onClick={() => increase(product, inventory)}
            className={`lg:h-8 h-7 bg-neutral-50 text-brand_indigo hover:opacity-80 focus:opacity-80 px-2 md:px-4 lg:text-lg md:text-base text-sm text-center font-semibold whitespace-nowrap select-none md:rounded-md border border-brand_indigo border-solid rounded font-manrope`}
            
          >
            ADD
          </button>
        </div>
      )
    )
    :
    (
      <div
        className={`lg:h-8 h-7 grid ${productQuantity == 0 || productQuantity == undefined
          ? 'grid-cols-1'
          : 'grid-cols-3 font-semibold'
          } text-base justify-center items-center ${btnStyle ? btnStyle : 'text-brand_indigo'
          } min-w-full`}
      >
        <div className="col-span-1 flex justify-start items-center">
          <button
            className="flex items-center lg:h-8 h-7 text-neutral-50 bg-brand_indigo border-1 border-solid border-brand_indigo rounded-md"
            onClick={(event) => increase(product, inventory, -1)}
          >
            {productQuantity == 1 ? <AiOutlineDelete /> : <AiOutlineMinus />}
          </button>
        </div>
        <div className="col-span-1 justify-center items-center text-center">
          <span>{productQuantity}</span>
        </div>
        <div className="col-span-1 flex justify-end items-center h-full">
          <button
            className={`flex items-center lg:h-8 h-7 text-neutral-50 bg-brand_indigo border-1 border-solid border-brand_indigo rounded-md cursor-not-allowed`}
            onClick={(event) => increase(product, inventory)}
            title="Increase Quantity"

          >
            <AiOutlinePlus />
          </button>
        </div>
      </div>
    );
};
