import { getStoreImages } from '@api/store';
import { CarouselContainer } from '@components/container/carousel';
import StoreReview from '@components/reviews/store-review';
import { StoreTiming } from '@components/storetiming';
import { TStore, TStoreTiming } from '@components/types';
import { getLocation } from '@core/geolocation';
import { getSafeUrl, sanityIoImageLoader, suggestColor } from '@core/utils';
import {
  useInView,
  useMotionValueEvent,
  useScroll,
} from 'framer-motion';
import { getDistance } from 'geolib';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import { FaRegStar } from 'react-icons/fa';
import { MdLocationPin, MdOutlinePhone, MdOutlineShare, MdVerified } from 'react-icons/md';
import { RWebShare } from 'react-web-share';
import { SwiperSlide } from 'swiper/react';

interface StoreTopCardProps {
  store: TStore;
  storeTimings: TStoreTiming[];
  isAvailable?: boolean
}

export function StoreTopCard({ store, storeTimings, isAvailable }: StoreTopCardProps) {
  const [storeImages, setStoreImages] = useState([]);
  const [localDistance, setLocalDistance] = useState < number > ();
  const [isVisible, setIsVisible] = useState(false);

  const ref = useRef();
  const isInView = useInView(ref);
  const { scrollY } = useScroll();

  async function fetchStoreImages() {
    const data = await getStoreImages(store?.id);
    setStoreImages(data);
  }

  async function fetchLocation() {
    const location = await getLocation();
    const distance = getDistance(
      {
        latitude: location?.latitude || 27.504,
        longitude: location?.longitude || 77.669,
      },
      {
        latitude: store?.address?.latitude || 27.504,
        longitude: store?.address?.longitude || 77.669,
      }
    );
    setLocalDistance(distance);
  }

  useMotionValueEvent(scrollY, 'change', (latest) => {
    const prev = scrollY.getPrevious();
    if (prev < latest) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  });

  useEffect(() => {
    fetchStoreImages();
    fetchLocation();
  }, [store]);


  return (
    <>
      <div className={`space-y-2 bg-brand_indigo/80 p-4 rounded-lg  ${isAvailable ? "grayscale-0" : "grayscale"}`}>
        <CarouselContainer>
          <SwiperSlide style={{ width: 'auto' }} className="min-w-0">
            <div className="flex-shrink-0">
              <Image
                loader={sanityIoImageLoader}
                src={
                  store?.image ||
                  'https://cdn.jhattse.com/public/consumer/placeholder/store.png'
                }
                className="rounded-md w-auto h-60"
                alt={store?.name}
                priority={true}
                width="200"
                height="200"
              />
            </div>
          </SwiperSlide>
          {storeImages?.map((image) => (
            <SwiperSlide style={{ width: 'auto' }} className="min-w-0">
              <div className="flex-shrink-0">
                <Image
                  loader={sanityIoImageLoader}
                  src={
                    image?.url ||
                    'https://cdn.jhattse.com/public/consumer/placeholder/store.png'
                  }
                  className="rounded-md w-auto h-60"
                  alt={store?.name}
                  width="200"
                  height="200"
                  loading="lazy"
                />
              </div>
            </SwiperSlide>
          ))}
        </CarouselContainer>

        <div className="space-y-2">
          <div className="flex justify-between">
            <div className="flex flex-col gap-0.5 font-manrope">
              <div className="flex gap-2 items-center flex-wrap">
                <h1 className="font-manrope text-xl text-neutral-100 font-bold">
                  {store?.name}
                </h1>
                {
                  store?.verified &&
                  <div className="flex gap-1 items-center z-10 w-max rounded-l-xl rounded-r-xl font-medium text-neutral-50 bg-verified py-1 pr-2 pl-1 select-none">
                    <span className='flex items-center text-xl'><MdVerified /></span>
                    <span className="text-sm">Trusted Seller</span>
                  </div>
                }
              </div>
              <span className="font-manrope text-sm text-neutral-200 font-semibold">
                {store?.category?.name}
              </span>
            </div>
          </div>
          <div className="flex gap-2 font-manrope">
            {store?.is_pickup && (
              <span className="h-8 px-4 flex items-center text-xs text-neutral-800 font-bold tracking-wider uppercase rounded bg-red-100">
                Pickup
              </span>
            )}
            {store?.is_delivery && (
              <span className="h-8 px-4 flex items-center text-xs text-neutral-800 font-bold tracking-wider uppercase rounded bg-blue-100">
                Delivery
              </span>
            )}
            {/* {store?.is_delivery && ( */}
            {/* <Link
              href={`/service/${store?.id}`}
              className="h-8 px-4 flex items-center text-xs text-neutral-800 font-bold tracking-wider uppercase rounded bg-blue-100 cursor-pointer">
              Booking
            </Link> */}
            {/* )} */}
          </div>
          <div className="grid grid-col-1 md:grid-cols-2 lg:justify-between gap-2.5">
            <div ref={ref} className="flex flex-col md:gap-2 gap-1.5">
              <StoreTiming storeTimings={storeTimings} timingColorClass="text-neutral-200" />

              <a href={`tel:${store?.phone}`}>
                <button
                  type="button"
                  className="flex items-center px-0 gap-2 hover:cursor-pointer"
                >
                  <MdOutlinePhone className="w-4 h-4 text-neutral-200" />
                  <span className="font-manrope text-sm text-neutral-200 font-semibold">
                    {store?.phone}
                  </span>
                </button>
              </a>
              <div className="flex items-center gap-2">
                <MdLocationPin className="w-4 h-4 text-neutral-200" />
                <p className="font-manrope text-sm text-neutral-200 font-semibold">
                  {store?.address?.street_name}, {store?.address?.city?.name}
                  {localDistance !== undefined &&
                    localDistance < 100 * 1000 && (
                      <div className="inline-flex whitespace-nowrap px-1 gap-1">
                        (
                        {localDistance < 1000
                          ? localDistance?.toFixed(0)?.toString() + ' m away'
                          : localDistance < 10000
                            ? (localDistance / 1000)?.toFixed(1)?.toString() +
                            ' km away'
                            : localDistance > 100 * 1000
                              ? null
                              : (localDistance / 1000)?.toFixed(0)?.toString() +
                              ' km away'}
                        ){' '}
                        {store?.address?.latitude != undefined && (
                          <Link
                            href={`http://www.google.com/maps/place/${store?.address?.latitude},${store?.address?.longitude}`}
                            target="_blank"
                            className="text-sm text-brand-500 font-semibold"
                          >
                            View Location
                          </Link>
                        )}
                      </div>
                    )}
                </p>
              </div>
              {
                store?.is_delivery &&
                <div>
                  {
                    store?.storemeta?.delivery?.delvery_time?.length > 0 &&
                    <p className="text-sm font-semibold font-manrope text-blue-100">Delivering in {store?.storemeta?.delivery?.delvery_time}</p>
                  }
                  {
                    store?.storemeta?.delivery?.return_available != null &&
                    <p className="text-sm font-semibold font-manrope text-blue-100">Returns {store?.storemeta?.delivery?.return_available ? "" : "not"} available</p>
                  }
                </div>
              }
            </div>

            <div className="flex flex-col md:items-end gap-2">
              <div className="flex md:flex-col md:items-end gap-2.5">
                <div className="flex items-center gap-2">
                  {store?.stats?.rating_overall > 0 && (
                    <div
                      className={`h-8 px-2.5 flex items-center gap-1 rounded ${suggestColor(
                        store.stats?.rating_overall
                      )}`}
                    >
                      <FaRegStar className="w-4 h-4 text-white" />
                      <span className="text-sm text-white font-bold tracking-wider uppercase">
                        {store.stats?.rating_overall}
                      </span>
                    </div>
                  )}
                  {store?.stats?.rating_count === 0 ? (
                    <span className="text-sm text-neutral-300 font-semibold">
                      No Reviews
                    </span>
                  ) : (
                    <Link
                      href={`/store/${store?.id}/reviews/${getSafeUrl(
                        store?.name
                      )}`}
                    >
                      <button
                        type="button"
                        className="text-sm text-brand-500 font-manrope font-medium hover:cursor-pointer"
                      >
                        View All Reviews
                      </button>
                    </Link>
                  )}
                </div>
                <StoreReview store_id={store?.id} />
              </div>
              <RWebShare
                data={{
                  title: 'Store',
                  text: `${store?.name} is on Jhattse`,
                  url: `/store/${store?.id}/${getSafeUrl(store?.name)}`,
                }}
              >
                <button className="flex items-center gap-1 hover:cursor-pointer">
                  <MdOutlineShare className="w-4 h-4 text-brand-500" />
                  <span className="text-sm text-brand-500 font-manrope font-medium">
                    Share
                  </span>
                </button>
              </RWebShare>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
